import React from 'react';

import { Card, Button } from 'reactstrap';
import hero3 from '../../assets/images/hero-bg/hero-3.jpg'


export default function EventStyleSelector({eventType, onClick}) {

  const setStyle = (buttonName, color) => {
    return buttonName == eventType ? color : `outline-${color}`
  }

  return (
    <Card className="card-box bg-composed-wrapper bg-slick-carbon border-1 border-first text-center mb-5 p-5 pb-4">
      <div className=" text-light mb-3">
        <h3 className="display-4">
          Select The Style Of Event
        </h3>
        <p className='text-white-50'>
          This will help us ask the right questions and speed up the process.
        </p>
      </div>
      <div className='w-100 d-flex justify-content-around flex-grow'>   

        <Button 
          onClick={onClick('stage')}
          size='large' 
          color={setStyle('stage', 'success')} 
          className='flex-grow-1 p-3 mx-2'>
          Stage
        </Button>

        <Button 
          onClick={onClick('conference')}
          size='large' 
          color={setStyle('conference', 'info')} 
          className='flex-grow-1 p-3 mx-2'>
          Conference
        </Button>

        <Button 
          onClick={onClick('tradeshow')}
          size='large' 
          color={setStyle('tradeshow', 'warning')} 
          className='flex-grow-1 p-3 mx-2'>
          Tradeshow
        </Button>

      </div>
    </Card>
  );
}
