import React, { Fragment } from 'react';

import Splash from './Splash'
import About from "../About"
import Process from "../Process"
import CallToAction from '../CallToAction'
import Testimonials from '../Testimonials'
import FAQ from '../FAQ'


const LandingPage = () => {
  return (
    <Fragment>
      <div className="rm-padding-wrapper">

        <Splash/>
        <About/>
        <Process/>
        <CallToAction/>
        <Testimonials/>
        {/* <FAQ/> */}
   
      </div>
    </Fragment>
  );
};

export default LandingPage;
