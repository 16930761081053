import React, { Fragment } from 'react';

import { Row, Col, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll'


//Brand

import brandLogo from '../../assets/Images/brand/RedTreeLogo.jpg'
//Avatars
import avatar1 from '../../assets/Images/avatars/avatar1.jpg';
import avatar2 from '../../assets/Images/avatars/avatar2.jpg';
import avatar6 from '../../assets/Images/avatars/avatar6.jpg';

import avatar3 from '../../assets/Images/avatars/avatar3.jpg'
import avatar4 from '../../assets/Images/avatars/avatar4.jpg'
import avatar5 from '../../assets/Images/avatars/avatar5.jpg'

//Organizations
import planningIcon from '../../assets/Images/planning_image_250.png'
import cancerSocietyLogo from '../../assets/Images/cns_logo.png'
import sinaiHealthSystem from '../../assets/Images/sinai_health_system_250.png'
import universityOfToronto from '../../assets/Images/university_of_toronto_250.png'

//Sponsors
import patagoniaLogo from '../../assets/Images/patagonia_logo_250.png'
import nikeLogo from '../../assets/Images/nike_logo_250.png'
import osbieLogo from '../../assets/Images/osbie_logo.bmp'

//Translation
import aslLogo from '../../assets/Images/asl_logo.png'
import stenographyLogo from '../../assets/Images/stenographer_logo.jpg'

//App integrations
import slidoLogo from '../../assets/Images/Apps/slido_logo_250.png'
import powerPointLogo from '../../assets/Images/Apps/powerpoint_logo_250.png'
import pllogo from '../../assets/Images/Apps//pl_logo.png'
import cmLogo from '../../assets/Images/Apps//cm_logo.jpg'
import zLogo from '../../assets/Images/Apps/zoom_logo.png'
import gDocsLogo from '../../assets/Images/Apps/google_docs.jpg'

//Other
import filmIcon from '../../assets/Images/film_icon.png'
import micIcon from '../../assets/Images/mic_icon.jpg'
import mixerIcon from '../../assets/Images/audio_mixer.png'


export default function LivePreviewExample() {
  return (
    <Fragment>
      <Element name='process'/>
      <div className="py-5">
        <div id='section-process' className="container pb-5">
          <div className="text-center py-5">
            <div className='mb-5'>
              <img src={brandLogo}/>
            </div>
            <h4 className="display-4 mb-2 font-weight-bold">
              <em>What's the process?</em>
            </h4>
            <p className="font-size-lg mb-5 text-black-50">
              While every event is different they all benefit from the same meticulous approach to execution.
              Technical preparation, consistent communication and putting out potential fires well in advance.
            </p>
            <h3 className='h3'>
              So what does that look like?
            </h3>
          </div>
          <Row>
            <Col md="4" sm="12">
              <h4 className="font-weight-bold">
                <Badge color="warning" className="px-4 h-auto py-1">
                  Consultation
                </Badge>
              </h4>
              <div className="timeline-list timeline-list--warning mb-5">
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Initial Event Overview
                    </h4>
                    <p>Tell us about your event and help us understand your vision. Give us an overview of the scope and timeline of the event.</p>
                    <div className='mt-3'>
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded mr-3 shadow-sm"
                          src={planningIcon}
                          width="70"
                        />
                      </a>
                      <div className="mt-2">
                      <Link to='/form' className='btn btn-sm btn-warning'>
                        Start Now
                      </Link>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Presenter | Attendee Assesment
                    </h4>
                    <p>
                      Discovery of presenter virtual and remote needs. Attendee overview and outline of any special circumstances.
                    </p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar1} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar2} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar6} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Organizational Branding
                    </h4>
                    <p>
                      Discussing and outlining options for the look and feel of the virtual conference platform.
                    </p>
                    <div className="mt-3">
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded mr-3 shadow-sm"
                          src={universityOfToronto}
                          width="70"
                        />
                      </a>
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded mr-3 shadow-sm"
                          src={cancerSocietyLogo}
                          width="70"
                        />
                      </a>
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded shadow-sm"
                          src={sinaiHealthSystem}
                          width="70"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Sponsors and Opportunities
                    </h4>
                    <p>
                      Discuss opportunities for sponsor visibility throught the conference.
                    </p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={patagoniaLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={nikeLogo} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Presentation Specs
                    </h4>
                    <p>
                      Outline presentation parameters and guidlines for presenters to follow.
                    </p>
                    <div className="mt-2">
                      <Button size="sm" disabled color="outline-warning">
                        Quick Specs
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Integrated Apps
                    </h4>
                    <p>
                      Discuss and outline any required app integrations. For example, Slido for polling and surveys.
                    </p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={slidoLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={powerPointLogo} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Button size="sm" disabled color="outline-warning">
                        Options
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Language Translation
                    </h4>
                    <p>
                      Simultaneous, post or script services for language translation. Stenography,
                      ASL, ADSL sign language needs.
                    </p>
                    <div className="mt-3">
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded mr-3 shadow-sm"
                          src={aslLogo}
                          width="70"
                        />
                      </a>
                      <a href="#/" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded mr-3 shadow-sm"
                          src={stenographyLogo}
                          width="70"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Filming and Post Production
                    </h4>
                    <p>
                      Creating permanent media assets for the organization and its sponsors.
                      Full planning for live multi-camera film shoots.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Pluck Service Modules
                    </h4>
                    <p>
                      Select appropriate service modules for your event.  Create a complete picture of 
                      the functionality and all of its dependencies.
                    </p>
                    <div className="mt-2">
                      <Link to='/services' className='btn btn-sm btn-outline-warning'>
                        Services
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-5 bg-warning p-3 rounded text-white'>
              <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Comprehensive Strategy
                    </h4>
                    <p>
                      Once all of the discovery and consulting is complete the preparation for the event can begin.
                      We start working on the physical preparation for the event. 
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4" sm="12">
              <h4>
                <Badge color="first" className="px-4 h-auto py-1">
                  Pre-Event
                </Badge>
              </h4>
              <div className="timeline-list timeline-list--first mb-5">
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Presentation Verification
                    </h4>
                    <p>Advanced collection, testing and verification of all presenter presentations.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Branding and Promotion Implementation
                    </h4>
                    <p>Promo video production, media bits creation and setup of branding materials.</p>
                  </div>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={patagoniaLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={osbieLogo} />
                        </div>
                      </div>
                    </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Platform Setup and Configuration
                    </h4>
                    <p>
                      Commissioning, setup and configuration of the platform. App integration and compatability tests.
                    </p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={pllogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={cmLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={zLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={gDocsLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={powerPointLogo} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={slidoLogo} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Attendee Registrations
                    </h4>
                    <p>Participant invitiations and registration verification. Event agendas and other virtual send outs.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Presenter Tech Rehearsals
                    </h4>
                    <p>Direct connection test with each presenter.  Ensure presenter is comfortable with the platform.</p>
                    <div className="mt-2">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar5} />
                        </div>
                      </div>
                    </div>       
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      On Site Studio Setup 
                    </h4>
                    <p>Location based filming and streaming studio setup. Custom solutions based on your needs and those of your presenters.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Network Optimization
                    </h4>
                    <p>Connection configurations, redundant bandwith implementation.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Integration Testing
                    </h4>
                    <p>Testing for Translation and other accessiblity services. Confirmation of additional tech. Polling and surveying for example.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      On Site Live Rehearsal
                    </h4>
                    <p>In the event that you are broadcasting from a central site. Dry runs for presenters, additional recording and media procurement.</p>
                  </div>
                </div>
              </div>

              <div className='mb-5 bg-first p-3 rounded text-white'>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Prepared to Launch
                    </h4>
                    <p>
                      All of the advanced configuration, testing and rehearsing is complete. At this point
                      the event is ready to launch.
                    </p>
                  </div>
                </div>
              </div>

            </Col>
            <Col md="4" sm="12">
              <h4>
                <Badge color="success" className="px-4 h-auto py-1">
                  Event
                </Badge>
              </h4>
              <div className="timeline-list timeline-list--success mb-5">
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Presenter Ready Room
                    </h4>
                    <p>Pre-connect and test each presenter in the ready room before they present.</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Attendee Support
                    </h4>
                    <p>Connection and standard tech level troubleshooting for attendees connecting.</p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar3} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={avatar4} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Moderation and Support
                    </h4>
                    <p>
                      Live stream moderation and support throughout the meeting. 
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      On Site Technical Operation
                    </h4>
                    <p>Full technical operation of on site filming, streaming and presenter support.</p>
                    <div className="avatar-wrapper-overlap mt-2 mb-1">
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={filmIcon} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={micIcon} />
                        </div>
                      </div>
                      <div className="avatar-icon-wrapper avatar-icon-sm">
                        <div className="avatar-icon">
                          <img alt="..." src={mixerIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Media Post Production
                    </h4>
                    <p>Prepartion of permanent video assets. Edited, professionally subtitled and organized.</p>
                    <div className="mt-2">
                      <Button size="sm" disabled color="outline-success">
                        Finalize
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Analytics And Reports
                    </h4>
                    <p>Reporting on who attended the meeting and feedback from users.</p>
                  </div>
                </div>
              </div>

              <div className='mb-5 bg-success p-3 rounded text-white'>
                <div className="timeline-item">
                  <div className="timeline-item--content">
                    <div className="timeline-item--icon" />
                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                      Completion and Reporting
                    </h4>
                    <p>
                      Post production, reporting and evaluation.
                    </p>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}
