import React, { Fragment } from 'react';

import { Row, Col, Card } from 'reactstrap';

import hero3 from '../../assets/images/hero-bg/hero-3.jpg';

export default function FormSectionContainer({children, title, description}) {
  return (
    <Fragment>
      <Card className="card-box mb-5 bg-slick-carbon">
        <Row className="no-gutters">
          <Col xl="5">
            <div className="hero-wrapper bg-composed-wrapper bg-grow-early h-100 rounded-left">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image rounded-left"
                  style={{ backgroundImage: 'url(' + hero3 + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-first opacity-8 rounded-left" />
                <div className="bg-composed-wrapper--content text-center p-5">
                  <div className="text-white">
                    <h1 className="display-3 my-3 font-weight-bold">
                      {title}
                    </h1>
                    <p className="font-size-lg mb-0 text-white-50">
                      {description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="7">  
            <div className='p-3'>    
            {children}
            </div>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}