import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element, Link as ScrollLink } from 'react-scroll'

import {
  Row,
  Col,
  Badge,
  Button
} from 'reactstrap';

import svgImage2 from '../../assets/images/illustrations/monitor.svg';

import hero6 from '../../assets/images/hero-bg/hero-6.jpg';

export default function LivePreviewExample() {
  return (
    <Fragment>
      <div className="rm-padding-wrapper ">
        <div className="hero-wrapper bg-composed-wrapper bg-white">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div
              className="bg-composed-wrapper--image bg-composed-filter-rm opacity-9"
              style={{ backgroundImage: 'url(' + hero6 + ')' }}
            />
            <div className="bg-composed-wrapper--content py-5">
              <div className="container py-5">
                <Row>
                  <Col xl="6">
                    <div className="d-flex align-items-center">
                      <Badge pill color="success" className="px-4 h-auto py-1">
                        End-to-End
                      </Badge>
                    </div>
                    <div className="text-black mt-3">
                      <h1 className="display-2 mb-3 font-weight-bold">
                        One Big Tool Box
                      </h1>
                      <p className="font-size-lg text-black-50">
                        Providing your attendees with a great live and virtual experience requires the right mix of services.
                        We bring flexible options, a deep tool box and the experience to pick the right solutions.
                      </p>
                      <div className="divider border-2 border-dark my-5 border-light opacity-2 rounded-circle w-25" />

                        <div className='d-inline-block mr-3'>
                          <ScrollLink to='virtual-services' className='btn btn-first btn-lg text-white d-' spy={true} smooth={true} offset={-50} duration={500}>
                            Virtual
                            <span className="btn-wrapper--icon ml-2 text-white">
                              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </span>                  
                          </ScrollLink>
                        </div>
                        <div className='d-inline-block mt-2'>
                          <ScrollLink to='on-site-services' className='btn btn-outline-first btn-lg' spy={true} smooth={true} offset={-50} duration={500}>
                            On-Location             
                          </ScrollLink>
                        </div>
                    </div>
                  </Col>
                  <Col
                    xl="6"
                    className="px-0 d-none d-md-flex align-items-center">
                    <img
                      alt="..."
                      className="w-100 mx-auto d-block img-fluid"
                      src={svgImage2}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
