import React from 'react'

import {Badge} from 'reactstrap'
import { Settings, Grid, Users, Layers } from 'react-feather';

const componentName = ({
  serviceName,
  IconBadge,
  isSelected,
  title,
  description,
  color,
  onClick,
}) => {

  const styles = {
    selected: {
      button: 'border-success bg-white text-black',
      description: `text-${color}`
    },

    deselected: {
      button: `border-color bg-slick-carbon text-white border-${color}`,
      description: `text-white-50`
    }
  }

  const selectedMode = isSelected ? 'selected' : 'deselected'


  const addedBadge = isSelected ?
    <div className="card-badges card-badges-top">
      <Badge color="success">
        Added
      </Badge>{' '}
    </div> : null

  return (
    <>
      <div className="w-50 p-2">
        <button
          onClick={onClick(serviceName)} 
          className={'btn card border text-left d-flex justify-content-center px-4 py-3 w-100 ' + styles[selectedMode].button}>

          <div>
            {addedBadge}

            <div className={`text-${color}`}>
              <IconBadge className='h1 d-block my-2'/>
            </div>

            <div className="font-weight-bold font-size-lg">
              {title}
            </div>

            <div className={'font-size-md mb-1' + styles[selectedMode].description}>
              {description}
            </div>
          </div>
        </button>
      </div>
    </>
  )
}

export default componentName
