const eventDefaults = {

  stage: {

    maxAudienceSize: 100,
    onSitePresenters: 10,
    remotePresenters: 2,
    concurrentRooms: 1,
    numberofExhibitors: 0,
    numberOfSponsorshipTiers: 0,
    numberOfSponsors: 1,

    requireHybridEventDirector: true, 
    requireOnSiteCommandCentre: false,
    requireOnSiteMainStageLive: true, 
    requireOnSiteFilmRecordPlus: false,
    requireOnpreEventVideoCapture: false,
    requireOnonSiteSatelliteSquad: false,
    requireRegistrationService: false,
    requirePresentations: true,
    requireNetworkingService: false,
    requireTradshowFloor: false,
    requireExhibitorLeads: false,
    requireCustomApp: false
  },
  
  conference: {
    maxAudienceSize: 200,
    onSitePresenters: 3,
    remotePresenters: 15,
    concurrentRooms: 4,
    numberofExhibitors: 0,
    numberOfSponsorshipTiers: 1,
    numberOfSponsors: 3,

    requireHybridEventDirector: true, 
    requireOnSiteCommandCentre: true,
    requireOnSiteMainStageLive: true, 
    requireOnSiteFilmRecordPlus: false,
    requireOnpreEventVideoCapture: false,
    requireOnonSiteSatelliteSquad: false,
    requireRegistrationService: true,
    requirePresentations: true,
    requireNetworkingService: false,
    requireTradshowFloor: false,
    requireExhibitorLeads: false,
    requireCustomApp: false
  },

  tradeshow: {

    maxAudienceSize: 500,
    onSitePresenters: 5,
    remotePresenters: 10,
    concurrentRooms: 1,
    numberofExhibitors: 50,
    numberOfSponsorshipTiers: 3,
    numberOfSponsors: 10,

    requireHybridEventDirector: true, 
    requireOnSiteCommandCentre: false,
    requireOnSiteMainStageLive: false, 
    requireOnSiteFilmRecordPlus: false,
    requireOnpreEventVideoCapture: true,
    requireOnonSiteSatelliteSquad: false,
    requireRegistrationService: true,
    requirePresentations: true,
    requireNetworkingService: true,
    requireTradshowFloor: true,
    requireExhibitorLeads: false,
    requireCustomApp: false
  }

}


  export default eventDefaults