import React, { Fragment } from 'react';

import { Row, Col, Badge } from 'reactstrap';

import mergeArrows from '../../assets/Images/merge_cable.png'

import Slider from 'react-slick';

import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'

import Particles from 'react-particles-js'
import particlesConfig from '../ParticleJs/particleJsConfig'

export default function About() {
  return (


      <div className="feature-box py-3 py-xl-5 bg-skim-blue" style={{position: 'relative'}}>   
        <Particles params={particlesConfig}/>
        <div className="container py-3 py-xl-5">
          <Row>
            <Col xl="6">
              <div className="py-0 pb-5 py-xl-5">
                <div className="pr-0 pr-xl-5">
                  <h1 className="display-3 mb-3 font-weight-bold text-white">
                    One Solution
                  </h1>
                  <h4 className='display-4 text-first'>Lost in an endless sea of options?</h4>
                  <p className="font-size-lg text-white bg-first p-3 rounded">
                    Your virtual event planning experience shouldn't feel like treading water in the open ocean.
                    Our team will help you pull together all the pieces, choose the right tools and execute a polished
                    event experience for your attendees.
                  </p>
                </div>
                <div className="d-block mt-4">
                  <Slider
                    slidesToShow={2}
                    slidesToScroll={2}
                    dots={true}
                    className="slick-slider slick-slider-left">
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <ScrollLink className='text-white btn btn-first' activeClass="active" to="process" spy={true} smooth={true} duration={500}>
                            Process
                          </ScrollLink>
                        </h3>
                        <p className="text-white mb-3">
                          Every great event starts with a plan. 
                          An inspiring blueprint to follow.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <Link to='/services' className='btn btn-first text-white'>
                            Services
                          </Link>
                        </h3>
                        <p className="text-white mb-3">
                          Every great event has challenges and requires flexible solutions.
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col xl="6" className="d-flex align-items-center">
              <img alt="..." className="img-fluid" src={mergeArrows} />
            </Col>
          </Row>
        </div>
      </div>
  );
}
