import React, { Fragment } from 'react'

import { Row, Col, Button, Badge } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'

import svgImage11 from '../../assets/images/illustrations/powerful.svg';
import treeSwingImage from '../../assets/images/illustrations/tree_swing.svg'
import hero10 from '../../assets/images/hero-bg/hybrid_event_camera_background.jpg'

const Splash = () => {
  return (
    <Fragment>
      <div className="hero-wrapper bg-composed-wrapper min-vh-100 bg-second">
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div
            className="bg-composed-wrapper--image bg-composed-filter-rm opacity-3"
            style={{ backgroundImage: 'url(' + hero10 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-8" />
          <div className="bg-composed-wrapper--bg bg-serious-blue opacity-2" />
          <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
            <div className="container pt-3 pb-4">
              <Row>
                <Col lg="7" xl="6" className="d-flex align-items-center">
                  <div>
                    <div className="d-flex align-items-center">
                      <Badge color="first" className="px-4 h-auto py-1">
                        Live
                      </Badge>" "
                      <Badge color="success" className="px-4 h-auto py-1">
                        Virtual
                      </Badge>" "
                      <Badge color="dark" className="px-4 h-auto py-1">
                        Events
                      </Badge>" "
                    </div>
                    <div className="text-white mt-3">
                      <h1 className="display-2 mb-3 font-weight-bold">
                        Hybrid-Conferences
                      </h1>
                      <p className="font-size-lg text-white-50">
                        Need to get your Conference or Tradeshow online? <strong className='text-white'>Relax</strong>... Our team has the perfect solution for you.
                        <strong> Start to Finish Virtual and On-site Service</strong>.
                      </p>
                      <h3>Your Complete Virtual Conference Solution</h3>
                      <p className="text-white">
                      </p>
                      <div className="divider border-2 border-dark my-4 border-light opacity-2 rounded-circle w-25" /> 
                      <div>
                          <Button
                            tag="a"
                            size="lg"
                            color="success"
                            className="px-5 py-3"
                            href="/form"
                            title="On a tight timeline? Connect with us now!">
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fas', 'external-link-alt']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              Quick Start!
                            </span>
                          </Button>
                        </div>            
                      <small className="d-block pt-4">
                        Now More than ever your conference needs to have a great virtual forum.  It can be a daunting 
                        task when you're not an experienced team. Relax, our team has everything you need.
                      </small>
                    </div>
                  </div>
                </Col>
                <Col
                  lg="5"
                  xl="6"
                  className="px-0 d-none d-lg-flex align-items-center">
                  <img
                    alt="..."
                    className="w-100 mx-auto d-block img-fluid"
                    src={treeSwingImage}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="hero-footer pt-2 pb-3 pb-lg-5 pb-lg-3">
          <div className="container">
            <div className="row pt-3">
              <div className="col-xl-4">
                <div className="feature-box rounded p-3 text-white mb-5 text-center">
                  <div className="bg-deep-blue text-center text-white font-size-xl mx-auto d-50 rounded-circle">
                    <FontAwesomeIcon icon={['fas', 'ethernet']} />
                  </div>
                  <h3 className="font-size-lg font-weight-bold mt-4">
                    Virtual
                  </h3>
                  <p className="text-white-50 mt-2">
                    Complete Event Virtualization Roadmap and process.
                  </p>
                  <ScrollLink className='btn btn-outline-danger' activeClass="active" to="process" spy={true} smooth={true} duration={500}>
                    Process
                  </ScrollLink>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="feature-box rounded p-3 text-white mb-5 text-center">
                  <div className="bg-grow-early text-center text-white font-size-xl mx-auto d-50 rounded-circle">
                    <FontAwesomeIcon icon={['fas', 'truck']} />
                  </div>
                  <h3 className="font-size-lg font-weight-bold mt-4">
                    On-Site
                  </h3>
                  <p className="text-white-50 mt-2">
                    Physical On-site Team and professional streaming.
                  </p>
                  <Link className='btn btn-outline-warning pill text-white' activeClass="active" to="/services">
                    Services
                  </Link>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="feature-box rounded p-3 text-white mb-5 text-center">
                  <div className="bg-strong-bliss text-center text-white font-size-xl mx-auto d-50 rounded-circle">
                    <FontAwesomeIcon icon={['fas', 'spell-check']} />
                  </div>
                  <h3 className="font-size-lg font-weight-bold mt-4">
                    Experienced
                  </h3>
                  <p className="text-white-50 mt-2">
                    Comprehensive event operation and oversight.
                  </p>
                  <Link to='/form' className='btn btn-outline-first text-white' activeClass="active">
                    Contact
                  </Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Splash
