import React, { Fragment, useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Collapse, ListGroup, ListGroupItem, Button, NavLink, NavItem } from 'reactstrap';

import RouterLink from './ReactMetismenuRouterLink';

import MetisMenu from 'react-metismenu';

import projectLogo from '../../assets/Images/project_logo.png';

const sidebarMenuContent = [
  {
    
    label: 'Home',
    to: '/',
    icon: 'pe-7s-smile',
  },
  {   
    label: 'Services',
    to: '/services',
    icon: 'pe-7s-tools',
  },
  {   
    label: 'Contact',
    to: '/form',
    icon: 'pe-7s-magnet',
  }
];

const HeaderMenu = () => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <Fragment>
      <div className="header-nav-menu d-none d-lg-block">
        <MetisMenu
          content={sidebarMenuContent}
          LinkComponent={RouterLink}
          activeLinkFromLocation
          iconNamePrefix=""
          noBuiltInClassNames={true}
          classNameItemActive="active"
          classNameIcon="header-icon-wrapper"
          iconNameStateVisible="nav-icon-indicator"
          iconNameStateHidden="nav-icon-indicator"
          classNameItemHasVisibleChild=""
          classNameItemHasActiveChild="active"
          classNameStateIcon="pe-7s-angle-down"
        />
      </div>
      <span className="d-block d-lg-none">
        <button
          onClick={toggle}
          className={clsx(
            'navbar-toggler-light navbar-toggler hamburger hamburger--elastic',
            { 'is-active': collapse }
          )}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </span>
      <Collapse
        isOpen={collapse}
        className="nav-collapsed-wrapper navbar-collapse">
        <div className="navbar-collapse-inner p-0">
          <ListGroup flush>
            <ListGroupItem className="bg-secondary px-4 py-3">
              <div className="d-flex align-items-stretch navbar-light align-content-center">
                <div className="header-nav-logo justify-content-start">
                  <a
                    href="/"
                    title="Home">

                    <div className="navbar-brand d-40">
                      <img
                        alt="Hybrid-Conferences"
                        src={projectLogo}
                        className="img-fluid my-auto"
                      />
                    </div>
                  </a>
                </div>
                <button
                  onClick={toggle}
                  className={clsx(
                    'navbar-toggler hamburger hamburger--elastic',
                    { 'is-active': collapse }
                  )}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </ListGroupItem>

            <ListGroupItem className="p-2">
              <a
                className="d-flex px-3 align-items-center dropdown-item rounded"
                href="#/"
                onClick={e => e.preventDefault()}>
                <div className="align-box-row w-100">
                  <div className="mr-3">
                    <div className="bg-malibu-beach text-center text-white d-40 rounded-circle">
                      <FontAwesomeIcon icon={['fas', 'cubes']} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold text-primary d-block">
                      Services
                    </div>
                    <small className="text-black-50">
                      Many services - One Solution
                    </small>
                  </div>
                  <div className="ml-auto card-hover-indicator align-self-center">
                    <FontAwesomeIcon
                      icon={['fas', 'chevron-right']}
                      className="font-size-lg"
                    />
                  </div>
                </div>
              </a>
            </ListGroupItem>
            <ListGroupItem className="p-2">
              <a
                className="d-flex px-3 align-items-center dropdown-item rounded"
                href="#/"
                onClick={e => e.preventDefault()}>
                <div className="align-box-row w-100">
                  <div className="mr-3">
                    <div className="bg-grow-early text-center text-white d-40 rounded-circle">
                      <FontAwesomeIcon icon={['fas', 'table']} />
                    </div>
                  </div>
                  <div>
                    <div className="font-weight-bold text-primary d-block">
                      Contact
                    </div>
                    <small className="text-black-50">
                      Get Connected With Us!
                    </small>
                  </div>
                  <div className="ml-auto card-hover-indicator align-self-center">
                    <FontAwesomeIcon
                      icon={['fas', 'chevron-right']}
                      className="font-size-lg"
                    />
                  </div>
                </div>
              </a>
            </ListGroupItem>
            
          </ListGroup>
        </div>
      </Collapse>
    </Fragment>
  );
};

export default HeaderMenu;
