import React, { Fragment, Component } from 'react';
import faqData from './faqdata'

import AccordianItem from './AccordianItem'

export default class LivePreviewExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accordion: faqData.map( e => false) //set the default state for each faq item to collapsed.
    };
  }

  toggleAccordion = (tab) => {
    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      accordion: state
    });
  }

  render() {
    const faqHtml = faqData.map( (element, index) => {
      return(
        <AccordianItem 
        question={element.question}
        answer={element.answer}
        panelNumber={index}
        panelOpen={this.state.accordion[index]} 
        toggleAccordion={this.toggleAccordion}/>
      )
    })
    return (
      <Fragment>      
        <div className="accordion mb-5">
          {faqHtml}
        </div>
      </Fragment>
    );
  }
}
