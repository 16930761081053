import React, { Fragment } from 'react';

import { Row, Col, CardBody, Card, Button, Badge } from 'reactstrap';
import mobileCommand from 'assets/Images/Services/on_site_command_centre.jpg';
import preRecording from 'assets/Images/Services/on_site_pre_recording.jpg';
import satelliteLocationRecording from 'assets/Images/Services/satellite_location_recording.jpg'

export default function LivePreviewExample() {
  return (
    <Fragment>
      <Row>
        <Col xl="4" lg="6">
          <Card className="mb-5">
            <img alt="..." className="card-img-top" src={mobileCommand} />
            <CardBody>
              <h5 className="card-title font-weight-bold font-size-lg">
                Mobile Command Centre
              </h5>
              <p className="card-text">
                Prefer to have us on-site working directly with your team for the event?
                We do that. We'll bring our mobile command centre right to your doorstep and
                work closely with your team.
              </p>
              <Badge pill color='warning'>On-Site</Badge>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="6">
          <Card className="mb-5">
            <img alt="..." className="card-img-top" src={preRecording} />
            <CardBody>
              <h5 className="card-title font-weight-bold font-size-lg">
                Live Web Broadcasting
              </h5>
              <p className="card-text">
                Comprehensive streaming coverage of your entire event as it happens live. 
                On location capture during the event by a talented team of streaming professionals.
              </p>
              <Badge pill color='warning'>On-Site</Badge>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="6">
          <Card className="mb-5">
            <img alt="..." className="card-img-top" src={satelliteLocationRecording} />
            <CardBody>
              <h5 className="card-title font-weight-bold font-size-lg">
                Satellite Location Recording
              </h5>
              <p className="card-text">
                Pre-Conference recording production for your high profile presenters. 
                Deliver polished presentation experiences without the stress of presenter mishaps and connection challanges.
              </p>
              <Badge pill color='warning'>On-Site</Badge>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
