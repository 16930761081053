import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './Components/LandingPage'
import InquiryForm from './Components/InquiryForm'
import Services from './Components/Services'


// Layout Blueprints

import PresentationLayout from './example-components/PresentationLayout';


const Routes = () => {

  return ( 
    <PresentationLayout>
      <Switch>
        <Route exact path='/form' component={InquiryForm}/>
        <Route exact path='/services' component={Services}/>  
        <Route component={LandingPage}/>
      </Switch>
    </PresentationLayout>
  );
};

export default Routes;
