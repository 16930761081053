import React from 'react';

import { 
  Row, 
  Col, 
  Card, 
  CardBody, 
  FormGroup, 
  Label, 
  Input, 
  Button
} from 'reactstrap'

import FormSectionContainer from './FormSectionContainer'
import DatePicker from './DatePicker'
import MdAlarm from 'react-ionicons/lib/MdAlarm';

export default function EventInfo({
  startDate,
  endDate,
  onInputChanged, 
  onDateChanged,
}) {
  return (
        
    <FormSectionContainer
      title="Event Info"
      description="Give us a quick brief on your event and let us know how to contact you."
    >
      <Card className="bg-slick-carbon text-white">
        <CardBody>
          <FormGroup>
            <Label htmlFor="eventName">Event Name</Label>
            <Input
              type="text"
              name="eventName"
              id="eventName"
              placeholder="Please enter the event name.."
              onChange={onInputChanged}
            />
          </FormGroup>
          <hr className='bg-dark my-4'></hr>
          <Row>
            <Col xl="6">
              <FormGroup>                      
                <Label>Starts On</Label>
                <DatePicker date={startDate} onChange={onDateChanged} dateName='startDate'/>
              </FormGroup>
            </Col>
            <Col xl="6">
              <FormGroup>                      
                <Label>Ends On</Label>
                <DatePicker date={endDate} onChange={onDateChanged} dateName='endDate'/>
              </FormGroup>
            </Col>
          </Row>
          <hr className='bg-dark my-4'></hr>
          <FormGroup>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Please enter your first name.."
              onChange={onInputChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Please enter your last name.."
              onChange={onInputChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Please enter your email"
              onChange={onInputChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="description">Description</Label>
            <Input 
              type="textarea" 
              name="description" 
              id="description" 
              rows="6" 
              placeholder='Please enter a brief description of you event.'
              onChange={onInputChanged}/>
          </FormGroup>
        </CardBody>
      </Card>

    </FormSectionContainer>

  );
}