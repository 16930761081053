import React from 'react'

import { 
  Card 
} from 'reactstrap'


import { Settings, Grid, Users, Layers } from 'react-feather';
import ServiceItem from './ServiceItem'

const LocationServices = (props) => {

  const {
    requireRegistrationService,
    requirePresentations,
    requireNetworkingService,
    requireTradshowFloor,
    requireExhibitorLeads,
    requireCustomApp,
    onClick
  }=props

  return (

    <Card className="card-box bg-composed-wrapper bg-slick-carbon text-center mb-5 py-5 border border-light">

      <span className="ribbon-angle ribbon-angle--top-right ribbon-light">
        <small>Virtual</small>
      </span>

      <div className=" text-light mb-3">
        <h4 className="display-4">
          Customize Your Virtual Services
        </h4>
        <p>
          Select the services you want included.
        </p>    
      </div>
      
      <div className="d-flex bg-slick-carbon card-footer flex-wrap align-items-stretch">

          <ServiceItem 
            serviceName='requireRegistrationService'
            IconBadge={Settings}
            isSelected={requireRegistrationService}
            title='Registration Portal'
            description='Virtual attendee registration'
            color='info'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requirePresentations'
            IconBadge={Grid}
            isSelected={requirePresentations}
            title='Virtual Presenters'
            description='Live Presentations'
            color='success'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireNetworkingService'
            IconBadge={Users}
            isSelected={requireNetworkingService}
            title='Networking'
            description='Attendee interactions'
            color='warning'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireTradshowFloor'
            IconBadge={Layers}
            isSelected={requireTradshowFloor}
            title='Tradeshow Floor'
            description='Virtual Exhibit Hall'
            color='danger'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireExhibitorLeads'
            IconBadge={Settings}
            isSelected={requireExhibitorLeads}
            title='Exhibitor Leads'
            description='Direct Leads Portal'
            color='primary'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireCustomApp'
            IconBadge={Grid}
            isSelected={requireCustomApp}
            title='Custom App'
            description='Android | IPhone | Web'
            color='dark'
            onClick={onClick}   
          />
      </div>
    </Card>
  )
}

export default LocationServices

