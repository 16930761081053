import React, { Fragment, Component } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import HeaderMenu from './HeaderMenu';

import brandLogo from '../../assets/Images/brand/RedTreeLogo.jpg';

class Header extends Component {
  render() {
    let { headerShadow } = this.props;

    return (
      <Fragment>
        <div className="header-nav-wrapper header-nav-wrapper-lg" />
        <div
          className={clsx(
            'app-header bg-second header-nav-wrapper header-nav-wrapper-lg w-100 navbar-light',
            { 'app-header--shadow': headerShadow }
          )}>
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <div className="nav-logo">
              <Link to="/" title="Hybrid-Conferences">
                <i>
                  <img
                    alt="Hybrid-Conferences"
                    src={brandLogo}
                  />
                </i>
                <span className="text-white">Hybrid Conferences</span>
              </Link>
            </div>
            <HeaderMenu/>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow
});

export default connect(mapStateToProps)(Header);
