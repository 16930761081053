import React, { Fragment, useState } from 'react';

import Hero from './Hero'
import Form from './Form'
import hero1 from '../../assets/images/hero-bg/hero-1.jpg';

import { Row, Col, Button } from 'reactstrap';
import hero8 from '../../assets/images/hero-bg/hero-8.jpg';

export default function InquiryFormIndex() {


  return (
    <Fragment>
      <div className="hero-wrapper bg-composed-wrapper bg-first rm-padding-wrapper">
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div
            className="bg-composed-wrapper--image opacity-3"
            style={{ backgroundImage: 'url(' + hero8 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-9" />
          <div className="bg-composed-wrapper--content py-5">
            <div className="container">
              <Row>
                <Col xl="8" className="mx-auto text-center py-3 mb-5">
                  <div className="text-white mt-1">
                    <h1 className="display-3 mb-1 font-weight-bold">
                      Tell Us About Your Event
                    </h1>
                    <p className="font-size-lg d-block mb-1 text-white-50">
                    <span className='border-bottom border-warning'>In a hurry?</span> Just fill out the first part.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Form/>
              </Row>
            </div>
          </div>
        </div>
      </div>
    
      <div className="rm-padding-wrapper ">
          <div className="hero-wrapper bg-composed-wrapper bg-serious-blue py-5">
            <div className="flex-grow-1 w-100 d-flex align-items-center pb-4">
              <div
                className="bg-composed-wrapper--image"
                style={{ backgroundImage: 'url(' + hero1 + ')' }}
              />
              <div className="bg-composed-wrapper--bg bg-second opacity-9" />
              <div className="bg-composed-wrapper--content">
                <div className="container py-4">
                  <Form/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  );
}
