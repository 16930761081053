const faqData = [
  {
    question: "Can my event have virtual breakout rooms?",
    answer:   "Yes you can."
  },

  {
    question: "Is there a limit to the number of virtual attendees I can have?",
    answer:   "Audience size is very scalable. We accommodate very large audiences."
  }
]

export default faqData