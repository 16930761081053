import React from 'react'
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, CardHeader, Collapse, Button } from 'reactstrap';

const componentName = ({question, answer, panelNumber, panelOpen, toggleAccordion}) => {
  return (
    <Card
      className={clsx('card-box', {
        'panel-open': {panelOpen}
      })}>
      <Card>
        <CardHeader>
          <div className="panel-title">
            <div className="accordion-toggle">
              <Button
                color="link"
                size="lg"
                className="d-flex align-items-center justify-content-between"
                onClick={() => toggleAccordion(panelNumber)}
                aria-expanded={panelOpen}>
                <span>{question}</span>
                <FontAwesomeIcon
                  icon={['fas', 'angle-up']}
                  className="font-size-xl accordion-icon"
                />
              </Button>
            </div>
          </div>
        </CardHeader>
        <Collapse isOpen={panelOpen}>
          <div className="p-4">
            {answer}
          </div>
        </Collapse>
      </Card>
    </Card>
  )
}

export default componentName
