import React from 'react'

import { 
  Card, 
  CardBody, 
  FormGroup, 
  Label, 
  Row,
  Col,

} from 'reactstrap'

  import Nouislider from 'nouislider-react'
  import wNumb from 'wnumb'

const AttendeeInfo = ({
  eventType,
  maxAudienceSize,
  onSitePresenters,
  remotePresenters,
  concurrentRooms,

  numberofExhibitors,
  numberOfSponsorshipTiers,
  numberOfSponsors,

  onSliderChanged,
}) => {

  let splitableContainer

  if( eventType === 'stage'){
    splitableContainer = {
      colSize: 7,
      styles: 'mx-auto'
    }
  }else{
    splitableContainer = {
      colSize: 6,
      styles: 'border-right border-first'
    }
  }

  return (
    <Card className="card-box bg-composed-wrapper bg-slick-carbon text-center mb-5 p-2 py-5 border-first">
      <span className="ribbon-angle ribbon-angle--top-right ribbon-first">
        <small>Guests</small>
      </span>

      <div className=" text-light mb-3">     
        <h4 className="display-4">
          Tell Us About Your Guests
        </h4>      
      </div>
      <CardBody className='w-100 d-flex flex-column text-white'>

        <Row>
          <Col md={splitableContainer.colSize} className={splitableContainer.styles}>
            <h5 className='display-5 text-warning'>
              Presenters & Audience
            </h5>
            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='maxAudienceSize'>Estimated Audience Size</Label>
              <div className='px-4 w-100'>
                <Nouislider
                  onChange={onSliderChanged('maxAudienceSize')}
                  connect
                  range={{ min: 50, max: 2500 }}
                  start={[maxAudienceSize]}
                  step={10}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                    
                  })}
                  className="slider-warning"
                />
              </div>
            </FormGroup>
            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='onSitePresenters'>On-Site Presenters</Label>
              <div className='px-4'>
                <Nouislider
                  onChange={onSliderChanged('onSitePresenters')}
                  range={{ min: 0, max: 50}}
                  start={[onSitePresenters]}
                  step={1}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-warning"
                />
              </div>
            </FormGroup>

            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='remotePesenters'>Remote Presenters</Label>
              <div className='px-4'>
                <Nouislider
                  onChange={onSliderChanged('remotePresenters')}
                  range={{ min: 0, max: 50}}
                  start={[remotePresenters]}
                  step={1}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-warning"
                />
              </div>
            </FormGroup>

            <FormGroup className='p-1'>
              <Label className='mb-5' htmlfor='concurrentRooms'>Concurrent Rooms</Label>
              <div className='px-4'>
                <Nouislider
                  onChange={onSliderChanged('concurrentRooms')}
                  range={{ min: 1, max: 20}}
                  start={[concurrentRooms]}
                  step={1}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-warning"
                />
              </div>
            </FormGroup>
            </Col>

          { eventType !== 'stage' ?
            <Col md={6}>
              <h5 className='display-5 text-success'>
                Exibitors & Sponsors
              </h5>
            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='numberofExhibitors'>Number of Exhibitors</Label>
              <div className='px-4 w-100'>
                <Nouislider
                  onChange={onSliderChanged('numberofExhibitors')}
                  connect
                  range={{ min: 0, max: 200 }}
                  start={[numberofExhibitors]}
                  step={5}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-success"
                />
              </div>
            </FormGroup>
            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='numberOfSponsorshipTiers'>Sponsorship Tiers</Label>
              <div className='px-4'>
                <Nouislider
                  onChange={onSliderChanged('numberOfSponsorshipTiers')}
                  range={{ min: 0, max: 6}}
                  start={[numberOfSponsorshipTiers]}
                  step={1}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-success"
                />
              </div>
            </FormGroup>

            <FormGroup className='p-3'>
              <Label className='mb-5' htmlfor='numberOfSponsors'>Number of Sponsors</Label>
              <div className='px-4'>
                <Nouislider
                  onChange={onSliderChanged('numberOfSponsors')}
                  range={{ min: 0, max: 50}}
                  start={[numberOfSponsors]}
                  step={1}
                  tooltips
                  format={wNumb({
                    decimals: 0,
                    thousand: ','
                  })}
                  className="slider-success"
                />
              </div>
            </FormGroup>
      
          </Col>

          : null }

        </Row>

      </CardBody>
    </Card>
  )
}

export default AttendeeInfo

