import React, { Component } from 'react'
import axios from 'axios'

import { 
  Row, 
  Col, 
  Form
} from 'reactstrap'

import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'

import { toast } from 'react-toastify'

import { Redirect } from 'react-router'


import eventTypeDefaults from './eventTypeDefaults'
import EventInfo from './EventInfo'
import AttendeeInfo from './AttendeeInfo'
import LocationServices from './LocationServices'
import VirtualServices from './VirtualServices'


import EventStyleSelector from './EventStyleSelector'


class componentName extends Component {
  state = {
    eventName: '',
    startDate: Date.now(),
    endDate: Date.now(),
    firstName: '',
    lastName: '',
    email: '',
    description: '',

    eventType: null,

    maxAudienceSize: 0,
    onSitePresenters: 0,
    remotePresenters: 0,
    concurrentRooms: 0,
    numberofExhibitors: 0,
    numberOfSponsorshipTiers: 0,
    numberOfSponsors: 0,

    requireHybridEventDirector: false, 
    requireOnSiteCommandCentre: false,
    requireOnSiteMainStageLive: false, 
    requireOnSiteFilmRecordPlus: false,
    requirePreEventVideoCapture: false,
    requireOnonSiteSatelliteSquad: false,
    requireRegistrationService: false,
    requirePresentations: false,
    requireNetworkingService: false,
    requireTradshowFloor: false,
    requireExhibitorLeads: false,
    requireCustomApp: false,

    formIsSending: false,
    redirect: null
  }

  onEventTypeChanged = (name) => () => {
    this.setState({
      ...this.state,
      ...eventTypeDefaults[name],
      eventType: name
    })
  }

  onInputChanged = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })  
  }

  onDateChanged = (dateName) => (dateValue) => {
    this.setState({
      ...this.state,
      [dateName]: dateValue
    })
  }

  toggleSwitch = (name) => (currentValue) => {
    this.setState({
      ...this.state,
      [name]: currentValue
    })
  }

  onToggleServiceSelected = name => e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      [name]: !this.state[name]
    })
  }

  onSliderChanged = name => value => {
    this.setState({
      ...this.state,
      [name]: value[0]
    })
  }

  setSubmitButtonSendState = (value) => {
    this.setState({
      ...this.state,
      formIsSending: value
    })
  }

  onSubmit = (e) => {
    const thisComponent = this;
    e.preventDefault();
    this.setSubmitButtonSendState(true)
    const formattedData = this.formatFormData();

    axios({
      method: 'post',
      url: 'https://formspree.io/xvowrgvy',
      data: formattedData
    })
    .then(function(response){
      thisComponent.setSubmitButtonSendState(false)

      if( response.status === 200){
        toast.success((

          <div>
            <h3>Success!</h3>
            <p>
              "Your Request Has Been Successfully Submitted!"
            </p>
          </div>),
          { containerId: 'A'}
        )
        thisComponent.setState({
          ...thisComponent.state,
          redirect: <Redirect to={{pathname: "/"}}/>
        })
      }
      else
      {
        toast.warning((
          <div>
            <h3>There may have been an issue.</h3>
            <p>
              "Please submit the form again."
            </p>
          </div>),
          { containerId: 'A'}
        )
      }
    })
    .catch(function (error){
      console.log(error)
      thisComponent.setSubmitButtonSendState(false)
      toast.error((
        <div>
          <p>Uh oh! An Error Has Occurred!</p>
          <ul>
            <li>Please try to submit the form again.</li> 
            <li>If the issue persists, please email us directly.</li>
          </ul>
        </div>),
        { containerId: 'A'}
      )
    });
  }

  formatFormData = () => {
    return {
      ...this.state,
      startDate: this.formatDate(this.state.startDate),
      endDate: this.formatDate(this.state.endDate)
    }
  }

  formatDate = (date) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
    const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date ) 
    return `${month}-${day}-${year}`
    
  }
  
  render() {
    return (
     
      <Form>
        {this.state.redirect} 
        <EventInfo 
          onInputChanged={this.onInputChanged}
          onDateChanged={this.onDateChanged}
          onSubmit={this.onSubmit}
          {...this.state}/>    

        <Row>
          <Col md={6} className='mx-auto mb-5'>
            <div className='w-75 mx-auto d-flex'>
              <LaddaButton
                className="btn btn-outline-warning p-3 flex-grow-1 border-1"
                loading={this.state.formIsSending}
                onClick={this.onSubmit}
                data-style={EXPAND_RIGHT}>
                In a Hurry? <strong>Click To Submit Now!</strong>
              </LaddaButton>
            </div>  
          </Col>
        </Row>    
        
        <EventStyleSelector eventType={this.state.eventType} onClick={this.onEventTypeChanged}/>
              
          <AttendeeInfo onSliderChanged={this.onSliderChanged} {...this.state}/>
        
          <LocationServices onClick={this.onToggleServiceSelected} {...this.state}/>

          <VirtualServices onClick={this.onToggleServiceSelected} {...this.state} /> 

          <Row>
            <Col md={6} className='mx-auto'>
              <div className='w-75 mx-auto d-flex'>
                <LaddaButton
                  className="btn btn-first p-3 flex-grow-1 border-1 border-white"
                  loading={this.state.formIsSending}
                  onClick={this.onSubmit}
                  data-style={EXPAND_RIGHT}>
                  Submit
                </LaddaButton>
              </div>  
            </Col>
          </Row>   
      </Form>      
    )
  }
}

export default componentName
