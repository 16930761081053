import React, { Fragment } from 'react';

import { Row, Col } from 'reactstrap';

import { Element } from 'react-scroll'

import OnSiteServices from './OnSiteServices'
import VirtualServices from './VirtualServices'

import Hero from './Hero'

export default function LivePreviewExample() {
  return (
    <Fragment>
      
      <Hero/> 

      <div class='container p-3'>
        <Element name='virtual-services'/>
        <Row>
          <Col md={10} className='mx-auto'>
          <div className="text-black my-5">
            <h3 className="display-4 mb-3 font-weight-bold">
              Virtual & Remote
            </h3>
            <p className="font-size-lg text-black-50">
              Your attendess and presenters deserve a smooth virtual experience. We strive to make
              sure all of your guests feel well looked after through the entire process.           
            </p>
            <hr></hr>
          </div>
          </Col>
        </Row>
        <Row>
          <VirtualServices/>
        </Row>
      </div>

      <div class='container p-3 mt-3'>
        <Element name='on-site-services'/>
        <Row>
          <Col md={10} className='mx-auto'>
          <div className="text-black my-5">
            <h3 className="display-4 mb-3 font-weight-bold">
              On Location
            </h3>
            <p className="font-size-lg text-black-50">
              Great virtual experiences still depend on expert on-site production knowledge and equipment.
              Setup, rehearsals and event execution can all be done on location with an expert team.                
            </p>
            <hr></hr>
          </div>
          </Col>
        </Row>
        <Row>
          <OnSiteServices/>
        </Row>
      </div>


    </Fragment>
  );
}
