import React, { Fragment } from 'react';

import { Row, Col, Card, Button } from 'reactstrap';

export default function LivePreviewExample() {
  return (
    <Fragment>
      <div class="container mt-5">
      <Row>
        <Col xl="12" className='mx-auto'>
          <Card className="card-box bg-neutral-first border-2 card-border-top border-first text-center mb-5">
            <div className="py-5 px-5">
              <h4 className="font-size-xl font-weight-bold mb-2">
                Get Started Now!
              </h4>
              <p className="opacity-6 font-size-md mb-3">
                We're excited to work with you on your upcoming event.
              </p>
              <Button
                color="first"
                href='/form'
                className="text-nowrap px-4 text-uppercase font-size-sm">
                <small className="font-weight-bold">Cet Connected</small>
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      </div>
    </Fragment>
  );
}
