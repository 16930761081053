import React, { Fragment } from 'react'

import { Row, Col, CardBody, Card, Badge } from 'reactstrap'

import getStarted from 'assets/Images/Services/get_started.jpg'
import presenterSupport from 'assets/Images/Services/presenter_support.jpg'
import virtualEventBuild from 'assets/Images/Services/virtual_event_build.jpg'

export default function VirtualServices() {
  return (
    <Fragment>
      <Row>
        <Col xl="4" lg="6">
          <Card className="mb-5">
            <img alt="..." className="card-img-top" src={getStarted} />
            <CardBody>
              <h5 className="card-title font-weight-bold font-size-lg">
                Strategic Planning
              </h5>
              <p className="card-text">
                There are a lot of options to consider when creating your virtual event. We work with you to assess
                event needs and deliver the best platform solution.
              </p>
              <Badge pill color='first'>On-Site</Badge>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="6">
          <Card className="mb-5">
            <img alt="..." className="card-img-top" src={virtualEventBuild} />
            <CardBody>
              <h5 className="card-title font-weight-bold font-size-lg">
                Virtual Event Build
              </h5>
              <p className="card-text">
                Putting all the right pieces into place to create the event can be a daunting task.
                We provide all the necessary know how and man power to bring it all together.
              </p>
              <Badge pill color='first'>Virtual</Badge>
            </CardBody>
          </Card>
          
        </Col>
        <Col xl="4" lg="6">
          <Card className="mb-5">
              <img alt="..." className="card-img-top" src={presenterSupport} />
              <CardBody>
                <h5 className="card-title font-weight-bold font-size-lg">
                  Presenter Support
                </h5>
                <p className="card-text">
                  It all starts with your presenters feeling comfortable and relaxed. We connect with each speaker
                  for pre-event testing and setup optimization.
                </p>
                <Badge pill color='first'>Virtual</Badge>
              </CardBody>
            </Card>
        </Col>
      </Row>
    </Fragment>
  );
}