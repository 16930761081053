import React, { Fragment, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormGroup,
  Button
} from 'reactstrap';

import DatePicker from 'react-datepicker';

const _DatePicker = ({dateName, date, onChange}) => {

  const CustomDateInput = ({ value, onClick }) => (
    <Button block color="second" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <FontAwesomeIcon icon={['far', 'calendar-alt']} />
      </span>
      <span className="btn-wrapper--label">{value}</span>
    </Button>
  );

  return (
    <Fragment>
      <DatePicker
        className="form-control"
        selected={date}
        onChange={onChange(dateName)}
        customInput={<CustomDateInput />}
      />
    </Fragment>
  );
};

export default _DatePicker;
