import React, { Fragment, Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button
} from 'reactstrap';

import projectLogo from '../../assets/Images/project_logo.png';

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div className="bg-second py-5">
          <div className="container py-5">
            <Row className="text-center text-xl-left">
              <Col xl="5" className="d-flex align-items-center">
                <div className="mb-5 mb-xl-0 w-100">
                  <div
                    className="nav-logo bg-white rounded-circle d-40 mx-auto mx-xl-0"
                    title="Welcome to UiFort 😀">
                    <a
                      href="#/"
                      onClick={e => e.preventDefault()}
                      className="d-block"
                      title="Bamburgh React UI Kit with Reactstrap PRO">
                      <i className="bg-transparent m-0 d-40">
                        <img
                          alt="Bamburgh React UI Kit with Reactstrap PRO"
                          src={projectLogo}
                        />
                      </i>
                    </a>
                  </div>
                  <p className="my-4 text-white-50">
                    We can't wait to work with you on your next event. 
                  </p>
                  <div>
                    <Button
                      tag="a"
                      href="/form"
                      color="first"
                      className="mr-3">
                      Contact us
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xl="7" className="d-none d-md-flex align-items-center">
                <Row className="w-100">
                  <Col lg="6">
                    <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                    <div className="pl-3">
                      <h6 className="text-white font-weight-bold mb-3">
                        Contact Us
                      </h6>
                      <Nav className="nav-transparent flex-column">
                        <NavItem>
                          <NavLink
                            href="/form"
                            className="px-0 py-1 text-white-50">
                            Contact Form
                          </NavLink>
                        </NavItem>
                        <NavItem>
                            <strong className='text-white'>info@redtree.ca</strong>
                        </NavItem>
                      </Nav>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                    <div className="pl-3">
                      <h6 className="text-white font-weight-bold mb-3">
                        Powered By Red Tree Productions
                      </h6>
                      <Nav className="nav-transparent flex-column">
                        <NavItem>
                          <NavLink
                            href="http://www.redtree.ca"
                            className="px-0 py-1 text-white-50">
                            Red Tree Website
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="px-0 py-1 text-white-50">
                            (519)-837-0000
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="divider border-2 d-none d-md-block rounded-circle border-white bg-white opacity-2 mx-auto my-5 w-50" />
            
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
