import React from 'react'

import { 
  Col,
  Card, 
  CardBody, 
  Badge

} from 'reactstrap'


import { Settings, Grid, Users, Layers } from 'react-feather';
import ServiceItem from './ServiceItem'

const LocationServices = (props) => {

  const {
    requireHybridEventDirector, 
    requireOnSiteCommandCentre,
    requireOnSiteMainStageLive, 
    requireOnSiteFilmRecordPlus,
    requirePreEventVideoCapture,
    requireOnonSiteSatelliteSquad,
    onClick
  }=props


  const setHighlight = (buttonName) => {
    return props[buttonName] === true ? 'border-white bg-white text-black' : `border-white' bg-slick-carbon text-white`
  }
  
  return (

    <Card className="card-box bg-composed-wrapper bg-slick-carbon text-center mb-5 py-5 border-dark">

      <span className="ribbon-angle ribbon-angle--top-right ribbon-dark">
        <small>On-Site</small>
      </span>

      <div className=" text-light mb-3">
        <h4 className="display-4">
          Customize Your On-Site Services
        </h4>
        <p>
          Select the services you want to included.
        </p>    
      </div>
      
      <div className="d-flex bg-slick-carbon card-footer flex-wrap align-items-stretch">

          <ServiceItem 
            serviceName='requireHybridEventDirector'
            IconBadge={Settings}
            isSelected={requireHybridEventDirector}
            title='Hybrid Event Director'
            description='Virtual Event Operator'
            color='success'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireOnSiteCommandCentre'
            IconBadge={Grid}
            isSelected={requireOnSiteCommandCentre}
            title='Command Centre'
            description='On-site Mission Control'
            color='info'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireOnSiteMainStageLive'
            IconBadge={Users}
            isSelected={requireOnSiteMainStageLive}
            title='Main Stage Live'
            description='On-Site Pro Streaming'
            color='warning'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireOnSiteFilmRecordPlus'
            IconBadge={Layers}
            isSelected={requireOnSiteFilmRecordPlus}
            title='Film Record Pro'
            description='Polished Video Recordings'
            color='danger'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requirePreEventVideoCapture'
            IconBadge={Settings}
            isSelected={requirePreEventVideoCapture}
            title='PreVent Capture'
            description='Pre-event Presentation Recording'
            color='primary'
            onClick={onClick}   
          />

          <ServiceItem 
            serviceName='requireOnonSiteSatelliteSquad'
            IconBadge={Grid}
            isSelected={requireOnonSiteSatelliteSquad}
            title='Satellite Squad'
            description='Remote On-Site Team'
            color='dark'
            onClick={onClick}   
          />
      </div>
    </Card>
  )
}

export default LocationServices

